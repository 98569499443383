<template>
  <div style="margin-bottom:80px;">
    <NavBar @click-left="onClickLeft" :fixed="true" left-arrow title="类别推荐"></NavBar>
    <div class="context-wrap">
      <div style="border-radius: 30px;overflow:hidden;margin:5px 10px 0 10px; ">
        <NoticeBar left-icon="info-o" text="需进行商标注册请点击联系顾问"></NoticeBar>
      </div>
      <div class="context-box">
        <div style="padding:10px;">
          <div style="font-weight:bold;">什么是下证率和星级？</div>
          <p>AI对商标注册成功率进行精确到小类的准确评估，以星级表示，星级越高，成功率越高，同时用下证率给您直观数据。</p>
          <div style="font-weight:bold;">什么是推荐类别？</div>
          <p>
            商标按类别注册，根据您输入的商品和服务，我们推荐保护以下类别。
          </p>
        </div>
      </div>
      <div style="text-align: center;padding:50px;" v-if="loading">
        <van-loading/>
      </div>
      <!--      商标注册类别-->
      <template v-if="brandCategory && brandCategory.length > 0">
        <template v-for="list in brandCategory">
          <div class="context-title">
            {{ Number(list.primary) === 1 ? '主要推荐类别' : '相关推荐类别' }}
          </div>
          <div class="context-box">
            <div class="context-list">
              <TheBrandCategory :data="list"></TheBrandCategory>
            </div>
          </div>
        </template>
      </template>
      <van-empty v-else-if="!loading && brandCategory.length === 0" image="search" description="未搜索到数据"/>
    </div>
    <consult></consult>
  </div>
</template>

<script>
import {
  Button,
  Skeleton,
  NavBar,
  Image as VanImage,
  Checkbox,
  Field,
  Toast,
  Icon,
  Radio,
  RadioGroup,
  NoticeBar
} from 'vant'

import TheBrandCategory from "@/views/fbw/components/TheBrandCategory";
import {getIndustry, getRate, queryRandomIssuingRate} from "@/api/api";
import Consult from "@/components/consult2";

export default {
  name: "orderDetail",
  data() {
    return {
      checkboxColor: '#FF5E00',
      brandCategory: [],
      paidOrderInfoTotals: [],
      loading: false,
      rates:{}
    }
  },
  mounted() {
    this.init()
  },
  filters: {
    tabTypeFilter(value) {
      let props = {0: '精准', 1: '近似'};
      return props[value] || '';
    }
  },
  computed: {},
  methods: {
    init() {
      this.loading = true;
      Promise.all([this.getData(), this.getRate(),this.queryRandomIssuingRate()]).then(res => {
        let [brandCategory, star,rates] = res;
        this.rates = rates;
        brandCategory.forEach(i => {
          i.sec.forEach(_i => {
            let fined = star[_i.num]
            if (fined) {
              _i['score'] = fined
            }
            if (_i.third) {
              _i.third.forEach(__i => {
                let _fined = star[__i.num]
                if (_fined) {
                  __i['score'] = _fined
                }
              })
            }
          })
        })
        this.brandCategory = brandCategory
        console.log(brandCategory);
        this.loading = false
      })
    },
    async getData() {

      let res = await getIndustry({
        text: this.$route.query.serviceName,
        allClass: 0,
        size: 10000,
        index: 1,
      })
      if (res.code === 200) {
        return res.data.one;
      }

    },
    async getRate() {
      let res = await getRate({
        text: this.$route.query.brandName
      });
      if (res.code === 200) {
        return res.data
      }
    },
    async queryRandomIssuingRate(){
      let res = await queryRandomIssuingRate();
      if(res.code === 200){
        return res.data
      }
    },
    onClickLeft() {
      this.$router.go(-1)
    }
  },
  components: {
    Consult,
    TheBrandCategory,
    Button, Skeleton, NavBar, VanImage, Checkbox, Field, Toast, Icon, Radio, RadioGroup, NoticeBar
  }
}
</script>

<style scoped>
.context-title {
  padding: 10px;
  font-weight: bold;
  font-size: 16px;
}

.context-wrap {
  overflow: scroll;
  padding-bottom: 60px;
  min-height: calc(100vh - 46px - 60px);
  padding-top: 45px;
}

.context-box {
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  margin: 6px 10px;
  align-items: center;
}


.context-list {
  width: 100%;
  padding-bottom: 7px;
}


.context-list-item > div {
  margin-right: 32px;
}

.context-list-item > div:last-child {
  margin-right: 0;
}


.context-list-cell > span {
  margin-right: 14px;
}


.poster-box {
  width: 50px;
  height: 50px;
  margin: 14px;
  overflow: hidden;
}

.title {
  font-size: 16px;
  font-weight: bold;
  padding: 16px;
}


.context-list-row > div:first-child {
  width: 70px;
}

.context-list-row > div:last-child {
  flex: 1;
}

.context-list-row > div {
  display: flex;
  align-items: center;
}


.bottom-menu > div {
  padding: 0 14px;
}
</style>
