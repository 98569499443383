<template>
	<!-- 咨询顾问 -->
	<div class="consult-box" safe-area-inset-bottom>
		<div class="cell"><span style="margin-right:25px;">顾 </span> 问：倪闰贵</div>
		<div class="cell" style="margin-left:88px;">153-8106-5862</div>
		<div class="cell">服务时间：工作日的9:00-18:00</div>
		<Button class="button-box" style="top:25px;" type="info" @click="go">立即办理</Button>
		<Button class="button-box" style="top:70px;" type="info" @click="onTelUs">联系顾问</Button>
	</div>
</template>

<script>
import { Button, Toast } from 'vant';
import { putContactConsult } from '../api/api';
export default {
	name: 'consult',
	components: { Button },
	data() {
		return {
			timer: 1
		};
	},
	methods: {
		async onTelUs() {
			let msg = '我们的顾问会及时联系您';
			Toast.loading({
				message: '加载中...',
				forbidClick: true
			});
      try {
        let userCode = this.$store.state.userInfo.userCode;
        let res = await putContactConsult({
          userCode,empCode:'EMP20211209133502284017'
        });
        if (res.code == 200) {
          Toast(msg);
          this.timer += 1;
        } else {
          this.timer = 1;
        }
      } catch (e) {
        //TODO handle the exception
      }
		},
    go(){
      let userInfo = window.localStorage.getItem('userInfo')
      if(userInfo) userInfo = JSON.parse(userInfo)
      let {userMobile, accessKey, userNickname, sign} = userInfo;
      window.location.href =`${process.env.VUE_APP_BASE_URL2}/#/?userMobile=${userMobile}&serviceName=${1}&accessKey=${accessKey}&userNickname=${userNickname}&sign=${sign}`
    }
	}
};
</script>

<style scoped>
.consult-box {
	position: fixed;
	z-index: 999;
	left: 0;
	right: 0;
	height: 86px;
	background: #ffffff;
	bottom: 0;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	border-top: 1px solid #f5f5f5;
	padding-bottom: 10px;
}
.button-box {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 10px;
	width: 103px !important;
	height: 38px !important;
	overflow: hidden;
}
.cell {
	height: 20px !important;
	width: 100%;
	margin-left: 20px;
	margin-bottom: 1px;
	font-size: 14px;
	color: #333333;
}
.cell:first-child {
	margin-top: 10px;
}
.cell:nth-child(2) {
	margin-left: 70px;
}
.cell:last-child {
	margin-bottom: 0;
}
</style>
